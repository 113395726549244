@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700,800);
@import url(https://fonts.googleapis.com/css?family=Orbitron);
@import url(https://fonts.googleapis.com/css?family=Audiowide);

html, body {
  height: 100%;
  margin: 0;
}

@media (min-width: 992px) {

    .full-screen-section{
        min-height: 100vh;
    }

    #products{
        min-height: 1000px;
    }

    #about{
        min-height: 875px;
    }
    .single-section-page{
        min-height: calc(100vh - 83px);
    }

    .half-screen-section{
        min-height: 50vh;
    }

    .med-screen-section{
        min-height: 53vh;
    }

    .small-screen-section{
        min-height: 47vh;
    }

    .team-text{
        height:425px;
    }

    .ie8 .full-screen-section{
        min-height: auto;
        height: auto;
    }

    .ie8 .single-section-page{
        min-height: auto;
    }

    .ie8 .half-screen-section{
        min-height: auto;
        height: auto;
    }

    .ie8 .med-screen-section{
        min-height: auto;
        height: auto;
    }

    .ie8 .small-screen-section{
        min-height: auto;
        height: auto;
    }

}


@media (min-width: 992px) and (max-width: 1199px ){
    .small-screen-section{
        height:auto;
    }

    .staff-img-wrapper{
        height:310px;
    }
}


.single-section-page{
    padding-bottom: 20px;
}

body {
    font: 300 14px/1.8 'Raleway', sans-serif;
    color: #666;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: #E7746F;
}

a:hover {
    text-decoration: none;
    color: #999;
}

/* ---------------------------------------------- /*
 * Transition elements
/* ---------------------------------------------- */

.navbar a,
.form-control {
    -webkit-transition: all 0.4s ease-in-out 0s;
       -moz-transition: all 0.4s ease-in-out 0s;
        -ms-transition: all 0.4s ease-in-out 0s;
         -o-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
}

a,
.btn {
    -webkit-transition: all 0.125s ease-in-out 0s;
       -moz-transition: all 0.125s ease-in-out 0s;
        -ms-transition: all 0.125s ease-in-out 0s;
         -o-transition: all 0.125s ease-in-out 0s;
            transition: all 0.125s ease-in-out 0s;
}

/* ---------------------------------------------- /*
 * Reset box-shadow
/* ---------------------------------------------- */

.btn,
.form-control,
.form-control:hover,
.form-control:focus,
.navbar-custom .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
}

/* ---------------------------------------------- /*
 * Typography
/* ---------------------------------------------- */

.giltbyte {
    font-family: Orbitron, sans-serif;
    font-weight: 300;
    font-style: normal;
    text-transform: none;
}

.three-d {
  text-shadow: 0 1px 0 #ccc,
               0 2px 0 #c9c9c9,
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 5px 0 5px;
    color: #222;
}

h2 {
    font-size: 30px;
    margin: 0 0 30px;
}

h3 {
    font-size: 13px;
    letter-spacing: 1px;
    margin: 0 0 5px;
}

h4 {
    font-size: 12px;
    letter-spacing: 1px;
}

h5 {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 400;
}

h6 {
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
}

/* ---------------------------------------------- /*
 * Reset border-radius
/* ---------------------------------------------- */

.btn,
.iconbox,
.progress,
#filter li,
.scroll-up a,
.form-control,
.input-group-addon {
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
         -o-border-radius: 3px;
            border-radius: 3px;
}


/* ---------------------------------------------- /*
 * Input-group
/* ---------------------------------------------- */

.input-group .form-control {
    z-index: auto;
}

.input-group-addon {
    background: #D8E1E4;
    border: 2px solid #D8E1E4;
    padding: 6px 15px;
    font-weight: 700;
}

/* ---------------------------------------------- /*
 * Inputs styles
/* ---------------------------------------------- */

.form-control {
    background: #FFF;
    border: 1px solid #D8E1E4;
    font-size: 14px;
    padding: 0 15px;
    color:#000;
}

.form-control:focus{
    border-color: rgba(135, 135, 135, 0.7);
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
    height: 38px;
    font-size: 13px;
}

.input-sm,
.form-horizontal .form-group-sm .form-control {
    font-size: 11px;
    height: 30px;
}

textarea.form-control {
    padding: 15px;
    max-width: 100%;
    min-width: 100%;
}

.form-group input{
    height:40px;
    // width: 100%;
}

.form-group{
    // margin: 0;
}
/* ---------------------------------------------- /*
 * Custom button style
/* ---------------------------------------------- */

.btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    padding: 8px 26px;
    border: 0;
    background-color: #fe3732;
    color: #fff;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    outline: inherit !important;
}

.btn:hover{
    background: #2c2c2c;
    color: #fff;
}

/* Button size */

.btn-lg,
.btn-group-lg > .btn {
    padding: 10px 25px;
    font-size: 13px;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 6px 25px;
    font-size: 11px;
}

.btn-xs,
.btn-group-xs > .btn {
    padding: 5px 25px;
    font-size: 10px;
}

.btn .icon-before {
    margin-right: 6px;
}

.btn .icon-after {
    margin-left: 6px;
}

/* Special buttons */

.box-btn{
    padding-left: 15px;
    padding-right:15px;
}

.cs-btn{
    width:100%;
    max-width:250px;
    white-space: normal;
    margin:3px;
    text-align: center !important;
}

.cs-btn + p{
    margin-top: 20px;
}

@media (max-width: 767px){
    .btn{
        white-space: normal;
    }
}

/* ---------------------------------------------- /*
 * General Styles
/* ---------------------------------------------- */

.pfblock {
    padding: 120px 0 100px;
}

.pfblock-gray {
    background: #f5f5f5;
}

.pfblock-image {
    padding: 0;
}

.calltoaction h1,
.calltoaction h2,
.calltoaction h3,
.calltoaction h4,
.calltoaction h5,
.calltoaction h6,
.pfblock-image,
.pfblock-image h1,
.pfblock-image h2,
.pfblock-image h3,
.pfblock-image h4,
.pfblock-image h5,
.pfblock-image h6 {
    color: #fff;
}

.pfblock-header {
    text-align: center;
    margin: 0 0 30px;
}

.pfblock-header-left {
    text-align: left;
}

.pfblock-header-left .pfblock-line {
    width: inherit;
    margin: 30px 0;
}

.pfblock-title{
    font-weight: 800;
}

.pfblock-subtitle {
    font-style: normal;
    font-size: 18px;
}

.pfblock-line {
    background: rgba(135, 135, 135, 0.5);
    width: 100px;
    height: 1px;
    margin: 10px auto;
}

.pfblock-icon {
    font-size: 32px;
}

.long-down {
    margin-bottom: 40px;
}

.all-caps{
    text-transform: uppercase;
}

.no-caps{
    text-transform: none;
}

figure.inline-img{
    width: 300px;
    margin: 15px;
    padding: 15px;
    text-align: right;
    background: #eee;
    border: 3px solid #ddd;
}

figure.portrait{
    width: 225px;
}

figure.inline-img img{
    width: 100%;
}

figure.inline-img.pull-left{
    margin-left: 0;
}

figure.inline-img.pull-right{
    margin-right: 0;
}

/* ---------------------------------------------- /*
 * Home
/* ---------------------------------------------- */

#home {
    background: url(../images/cover.jpg);
    background-color: #222;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    padding: 0;
}

.home-overlay {
    background-color: rgba(44, 62, 80, 0.3);
    background-image: url("../images/pattern.png");
    background-repeat: repeat;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.intro {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    text-align: center;
    -webkit-transform: translate(0%, -50%);
       -moz-transform: translate(0%, -50%);
        -ms-transform: translate(0%, -50%);
         -o-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    padding: 0 15px;
}

.intro h1{
    font-weight: 800;
}

.intro h1 .heading{
    font-size: 80px;
    display: inline-block;
    text-transform: uppercase;
}

.intro h1 img{
    width: 150px;
    height: 150px;
    margin-top: -40px;
    display: inline-block;
}

.heading {
    font-size: 40px;
    margin: 15px 0;
}

.tagline1 {
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 5px 0 5px;
    line-height: 40px;
}

.tagline2 {
    font-size: 16px;
    font-style: normal;
    text-transform: none;
    margin: 0;
    line-height: 4px;
}

#iso-logos img{    
    width: 150px;
    height: 150px;
}

@media (max-width: 768px){
    .intro h1{
        font-weight: 650;
    }

    .intro h1 .heading{
        font-size: 25px;
    }

    .intro h1 img{
        width: 75px;
        height: 75px;
        margin-top: 0;
    }

    .heading {
        font-size: 40px;
        /*margin: 15px 0;*/
    }

    .tagline1 {
        font-weight: 500;
        font-size: 20px;
        /*margin: 5px 0 5px;*/
        line-height: 20px;
    }

    .tagline2 {
        font-size: 14px;
    }
}


/* ---------------------------------------------- /*
 * Product
/* ---------------------------------------------- */

#product-title{
    margin-bottom: 10px;
}

#product-header{
    margin:0;
}

#timesheet{
background-color: #EEEEEE;
}

#absence{

}

#leave{
background-color: #EEEEEE;
}

.info-box{
    border: 1px solid #222;
    text-align: center;
    padding: 10px;
}

.info-box-payslip{
    height:375px;
}


.product-detail{
    background: #222;
}

.product-boxes section{

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
         -o-border-radius: 3px;
            border-radius: 3px;
    padding: 15px;
    margin-bottom: 15px;
    color: white;
}

.product-boxes h2{
    color: white;
}

.raise{
    position: relative;
    top: -100px;
}

.jumbotron.extended{
    padding-bottom: 100px;
}

/* ---------------------------------------------- /*
 * About
/* ---------------------------------------------- */

.iconbox {
    background: #fff;
    border: 1px solid #166fb2;
    text-align: center;
    padding: 40px 20px;
    margin: 0 0 20px;
}


#subtitle-link{
    font-weight: bold;

}

.iconbox.highlight{
    border-color: #fe3732;
}

.iconbox-icon {
    margin: 0 0 15px;
    font-size: 32px;
    color: #222;
}

.iconbox-title {
    margin: 0 0 15px;
    padding: 0;
}

.iconbox-desc{
    text-align: left;
}

.iconbox.wide-fix {
    margin-left: 15px;
    margin-right: 15px;
}

/* ---------------------------------------------- /*
 * Studies
/* ---------------------------------------------- */

.case-study h3 {
    margin-top: 30px;
    font-size: 16px;
}

.case-study p {
    margin-top: 20px;
    color: #222;
}

h3 .big{
    font-size: 25px;
    margin-bottom: 20px;
    display: block;
}

.case-study a {
    background-color: white;
    min-height: 325px;
    margin: 50px 20px 0 20px;
    display: block;
    padding: 10px;
    border: 1px solid transparent;
    -webkit-transition : border 500ms ease-out;
    -moz-transition : border 500ms ease-out;
    -o-transition : border 500ms ease-out;
    transition : border 500ms ease-out;
}

.case-study a:hover {
    border: 1px solid #e7746f;
}

#case-study-bar a {
    padding-top:20px;
}

.line-info{
    margin-top: 35px;
}

.percent:after {
    content: "%";
    font-size: 50%;
    margin-left: 0.1em;
}

.additional-studies{
    text-align: center;
    font-size: 18px;
}

/* ---------------------------------------------- /*
 * Contact
/* ---------------------------------------------- */

.ajax-response {
    text-align: center;
}

/* ---------------------------------------------- /*
 * Footer
/* ---------------------------------------------- */

#footer {
    background: #222;
    text-align: center;
    padding: 3px 0;
    color: #FFF;
    border-top: 1px solid #f5f5f5;
    width: 100%;
    z-index: 998;
    line-height: 28px;
}

#footer p{
    margin: 0;
}

.contact-info{
    font-size: 15px;
}

.copyright {
    font-size: 11px;
}

.policies{
    font-size: 11px;
}

@media (max-width: 767px) {
    .copyright {
        line-height: 12px;
    }

    .contact-info{
        line-height: 18px;
    }

    .policies{
        line-height: 12px;
    }

    .bottom .copyright {
        display: none;
    }

    .bottom .policies{
        display: none;
    }

    #footer p{
        -webkit-transition : visibility 1s ease-out;
        -moz-transition : visibility 1s ease-out;
        -o-transition : visibility 1s ease-out;
        transition : visibility 1s ease-out;
    }

}

@media (min-width: 768px) {
    .copyright {
        text-align: left;
    }

    .policies{
        text-align: right;
        float: right;
    }
}

/* ---------------------------------------------- /*
 * Navigation
/* ---------------------------------------------- */

.header {
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    width: 100%;
    z-index: 998;
}

.nav-logo{
	float: left;
	margin: -6px 5px 0 15px;
}

.navbar {
    border: 0;
    border-radius: 0;
    margin: 0;
}

.navbar .navbar-brand {
    font-size: 22px;
    color: #fff;
}

.navbar .dropdown-menu {
    border: 0;
    border-top: 2px solid #E7746F;
    border-radius: 0;
    background: #222;
}

.navbar .dropdown-menu > li > a {
    padding: 10px 20px;
    color: #9d9d9d;
}

.navbar .nav .open > a,
.navbar .dropdown-menu > li > a:hover,
.navbar .dropdown-menu > li > a:focus {
    color: white;
    background: #222;
}

.navbar li a.active, .navbar a.active{
    color:#E7746F;
}

#cookie-bar + .navbar{
    padding-top: 30px;
}

/* ---------------------------------------------- /*
 * Scroll controls
/* ---------------------------------------------- */

.scroller {
    position: fixed;
    z-index: 999;
    bottom: 2.5em;
}

#homePage .scroller {
    display: none;
}

.scroll-up {
	right: 2em;
}

.scroll-down {
	right: 5em;
}

.scroller a {
    background-color: rgba(135, 135, 135, 0.5);
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    line-height: 30px;
}

.scroller a:hover,
.scroller a:active {
    background-color: rgba(235, 235, 235, .8);
    color: #e7746f;
}

/* ---------------------------------------------- /*
 * Scroll to start from home page
/* ---------------------------------------------- */

.scroll-start {
    position: absolute;
    left: 50%;
    bottom: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-left: -25px;
    display: block;
    z-index: 10;
    text-align: center;
}

.scroll-start span{
    position: relative;
    color: #fff;
    -webkit-animation-name: drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-name: drop;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@-webkit-keyframes drop    {
    0%   { top:0px;  opacity: 0;}
    30%  { top:10px; opacity: 1;}
    100% { top:25px; opacity: 0;}
}

@keyframes drop    {
    0%   { top:0px;  opacity: 0;}
    30%  { top:10px; opacity: 1;}
    100% { top:25px; opacity: 0;}
}

/* ---------------------------------------------- /*
 * Preloader
/* ---------------------------------------------- */

#preloader {
    background: #FFF;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}

#status {
    background-image: url(../images/preloader.gif);
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    left: 50%;
    margin: -100px 0 0 -100px;
    position: absolute;
    top: 50%;
    width: 200px;
}

/* ---------------------------------------------- /*
 * Media
/* ---------------------------------------------- */

@media (max-width: 767px) {

    .calltoaction {
        text-align: center;
    }

}

@media (max-width: 480px) {

    h1 {
        font-size: 34px;
        letter-spacing: 3px;
    }

    h2 {
        font-size: 24px;
        margin: 0 0 30px;
        letter-spacing: 3px;
    }

}

/*------------------------*/
/*****  Common styles *****/
/*------------------------*/

.grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 1em 0 4em;
    max-width: 100%;
    list-style: none;
    text-align: center;
}

.grid h2{
    font-size: 20px;
}

.grid figure {
    position: relative;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
    background: #9e5406;
}

figure.effect-bubba img {
    opacity: 0.7;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-bubba:hover img,
figure.effect-bubba.touch img {
    opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
}

figure.effect-bubba h2 {
    padding-top: 2.5%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,-20px,0);
    transform: translate3d(0,-20px,0);
    color: #fff;
}

figure.effect-bubba p {
    padding: 10px 1.5em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
    /*text-align: justify;*/
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after,
figure.effect-bubba.touch figcaption::before,
figure.effect-bubba.touch figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

figure.effect-bubba.touch figcaption::before,
figure.effect-bubba.touch figcaption::after {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p,
figure.effect-bubba.touch h2,
figure.effect-bubba.touch p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-bubba.touch p{
padding: 10px 10px;
text-align: justify;
font-size: 11px;
}

/*--------------------*/
/*       NO BUBBA     */
/*--------------------*/
figure.no-bubba figcaption{
    /*background: #9e5406;*/
    background-color:#a9bdd1;
    opacity: 0.8;
}

figure.no-bubba{
    /*opacity: 1;*/
    color: #fff;
}

figure.no-bubba h2, figure.no-bubba p{
    color: #fff;
    opacity: 1;
}



/*-----------------------*/
/***** Quote Rotator *****/
/*-----------------------*/

.cbp-qtrotator {
    position: relative;
    margin: 3em auto 0em auto;
    max-width: 90%;
    width: 90%;
}

.cbp-qtrotator .cbp-qtcontent {
    position: absolute;
    min-height: 200px;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 2em 0;
    top: 0;
    z-index: 0;
    opacity: 0;
    width: 100%;
}

.ie8 .cbp-qtrotator .cbp-qtcontent {
    display: none;
}

.no-js .cbp-qtrotator .cbp-qtcontent {
    border-bottom: none;
}

.cbp-qtrotator .cbp-qtcontent.cbp-qtcurrent,
.no-js .cbp-qtrotator .cbp-qtcontent {
    position: relative;
    z-index: 100;
    pointer-events: auto;
    opacity: 1;
}

.ie8 .cbp-qtrotator .cbp-qtcontent.cbp-qtcurrent,
.ie8 .no-js .cbp-qtrotator .cbp-qtcontent {
    display: block;
}

.cbp-qtrotator .cbp-qtcontent:before,
.cbp-qtrotator .cbp-qtcontent:after {
    content: " ";
    display: table;
}

.cbp-qtrotator .cbp-qtcontent:after {
    clear: both;
}

.cbp-qtprogress {
    position: absolute;
    background: #FF5252;
    height: 1px;
    width: 0%;
    top: 0;
    z-index: 200;
}

.cbp-qtrotator blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
}

.cbp-qtrotator blockquote p {
    font-size: 2em;
    color: #888;
    font-weight: 300;
    margin: 0.4em 0 1em;
}

.cbp-qtrotator blockquote footer {
    font-size: 1.2em;
}

.cbp-qtrotator blockquote footer:before {
    content: '― ';
}

.cbp-qtrotator .cbp-qtcontent img {
    float: right;
    margin-left: 3em;
}

/* Example for media query */
@media screen and (max-width: 30.6em) {

    .cbp-qtrotator {
        font-size: 70%;
    }

    .cbp-qtrotator img {
        width: 80px;
    }

}


/*---------------------------*/
/***** Case Study Pages *****/
/*---------------------------*/

.page-body{
    min-height: 100%;
    height: 100%;
    background-color: #5c5c5c;
    background-image:linear-gradient(180deg, #2c2c2c, #5c5c5c);
    background-attachment: fixed;

}

.text-wrapper{
    background-color: white;
    border: 1px solid rgba(0, 0, 0, .5);
    margin: 30px 0 57px 0;
    padding: 30px 0;
}

.main-header{
    font-weight: bold;
    text-align: center;
}

.sub-header{
    text-align: center;
    font-size: 1.8em;
}

.text-image-right{
    display: block;
}

.text-image-right p {
    display: inline-block;
    width:75%;
    float: left;
}

.text-image-right img {
    display: inline-block;
    width:20%;
}

.animation-wrapper{
    position: relative;
    top: 100px;
}

.inline-img.pull-right{
    text-align: right;
}

.inline-img.pull-left{
    text-align: left;
}

.text-bold{
    font-weight: bold;    
    font-size: 14;
    color: #E7746F;
}

.helpdesk{    
    color: black;
}

@media (max-width: 766px){
    figure.inline-img{
        width: 100%;
        margin: 15px 0;
    }
}


/* GRAPH */


.line1, .line2, .line3 {
    background:#2187e7;
    width:0px;
    height:1px;
}

.line-left, .line-bottom{
    background:#000;
    width:135px;
    height:1px;
    position:relative;
    bottom: -10px;
    left: -5px;
}

.line-left {
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    width:100px;
    bottom:-11px;
}

.running .line1 {
    -moz-transform:rotate(-10deg);
    -webkit-transform:rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);

    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;

    -moz-animation:move 1s linear forwards;
    -webkit-animation:move 1s linear forwards;
    animation:move 1s linear forwards;
    position:relative;
    bottom:15px;
    left:0;

}

.running .line2 {
    -moz-transform:rotate(-30deg);
    -webkit-transform:rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);

    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;

    -moz-animation:move1 1s 1s linear forwards;
    -webkit-animation:move1 1s 1s linear forwards;
    animation:move1 1s 1s linear forwards;

    position:relative;
    left:50px;
    bottom:50px;
}

.running .line3 {
    -moz-transform:rotate(-65deg);
    -webkit-transform:rotate(-65deg);
    -ms-transform: rotate(-65deg);
    transform: rotate(-65deg);

    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;

    -moz-animation:move2 1s 2s linear forwards;
    -webkit-animation:move2 1s 2s linear forwards;
    animation:move2 1s 2s linear forwards;
    position:relative;
    left:95px;
    bottom: 100px;

}

.running .ball,.running  .ball1,.running  .ball2,.running  .ball3,.running  .ball4 {
    background-color:#2187e7;
    background-image: -moz-linear-gradient(90deg, #2187e7 25%, #a0eaff);
    background-image: -webkit-linear-gradient(90deg, #2187e7 25%, #a0eaff);
    width:10px;
    height:10px;
    border-radius:50px;
    z-index:99;
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);

    -moz-animation: point 0.1s linear forwards;
    -webkit-animation: point 0.1s linear forwards;
    animation: point 0.1s linear forwards;

}

.running .pulse,.running .pulse1,.running  .pulse2,.running  .pulse3,.running  .pulse4 {
    width:12px;
    height:12px;
    border-radius:30px;
    border: 1px solid #00c6ff;
    box-shadow: 0 0 5px #00c6ff;

    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);

    -moz-animation: pulse 1s ease-out;
    -webkit-animation: pulse 1s ease-out;
    animation: pulse 1s ease-out;
}

/* COORDINATE POINTS */

.ball1 { position:relative; bottom:-5px; left:-5px; }
.ball2 { position:relative; bottom:30px; left:42px;}
.ball3 { position:relative; bottom:83px; left:90px; }
.ball4 { position:relative; bottom:150px; left:112px; }

.pulse1 { position:relative; bottom:7px; left:-7px; }
.pulse2 { position:relative; bottom:42px; left:40px; }
.pulse3 { position:relative; bottom:95px; left:88px; }
.pulse4 { position:relative; bottom:162px; left:110px; }

/* CONTROL LAYER DELAY */

#layerBall, #layerPulse {
    -moz-animation-delay:0s;
    -webkit-animation-delay:0s;
    animation-delay:0s;
}
#layerBall1, #layerPulse1 {
    -moz-animation-delay:0.9s;
    -webkit-animation-delay:0.9s;
    animation-delay:0s;
}
#layerBall2, #layerPulse2 {
    -moz-animation-delay:1.9s;
    -webkit-animation-delay:1.9s;
    animation-delay:0s;
}
#layerBall3, #layerPulse3 {
    -moz-animation-delay:2.9s;
    -webkit-animation-delay:2.9s;
    animation-delay:0s;
}

/* ANIMATION */

@-moz-keyframes move {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@-moz-keyframes move1 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5);}
}
@-moz-keyframes move2 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5);}
}
@-moz-keyframes move3 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5);}
}

@-moz-keyframes point {
    0%  {-moz-transform:scale(0,0);}
    100%{-moz-transform:scale(1,1);}
}

@-moz-keyframes pulse {
    0%   {-moz-transform: scale(0);opacity: 0;}
    10%  {-moz-transform: scale(1);opacity: 0.5;}
    50%  {-moz-transform: scale(1.75);opacity: 0;}
    100% {-moz-transform: scale(0);opacity: 0;}
}

@-webkit-keyframes move {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@-webkit-keyframes move1 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@-webkit-keyframes move2 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@-webkit-keyframes move3 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}

@-webkit-keyframes point {
    0%  {-webkit-transform:scale(0,0);}
    100%{-webkit-transform:scale(1,1);}
}

@-webkit-keyframes pulse {
    0%   {-webkit-transform: scale(0);opacity: 0;}
    10%  {-webkit-transform: scale(1);opacity: 0.5;}
    50%  {-webkit-transform: scale(1.75);opacity: 0;}
    100% {-webkit-transform: scale(0);opacity: 0;}
}

@keyframes move {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@keyframes move1 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@keyframes move2 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}
@keyframes move3 {
    0%   { width:0px;}
    100% { width:50px; box-shadow:0px 0px 5px 1px rgba(0,198,255,0.5); }
}

@keyframes point {
    0%  {transform:scale(0,0);}
    100%{transform:scale(1,1);}
}

@keyframes pulse {
    0%   {transform: scale(0);opacity: 0;}
    10%  {transform: scale(1);opacity: 0.5;}
    50%  {transform: scale(1.75);opacity: 0;}
    100% {transform: scale(0);opacity: 0;}
}

/* BAR CHART */

#chart, .line-content{
  height: 120px;
  margin: 5px auto 0;
  display: block;
}

.line-content{
    width: 300px;
    position: relative;
    top:100px;
    left:75px;
    padding-bottom:100px;
}

#bars{
  display: inline-block;
  padding-bottom: 1px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

#chart #bars li {
  display: table-cell;
  width: 70px;
  height: 120px;
  text-align: center;
  position: relative;
}

#chart #bars li .bar {
  display: block;
  width: 50px;
  position: absolute;
  bottom: 0;
}

#chart #bars li span {
  color: #FFF;
  width: 100%;
  position: absolute;
  bottom: -2em;
  left: 0;
  text-align: center;
}

ul#bars{
    width:210px;
    margin:0;
    padding-left: 15px;
}

.bar{
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    width:75px;
}

/* CONTROL DELAY */

.running-bar #bar1 {
    -moz-animation-delay:0s;
    -webkit-animation-delay:0s;
    animation-delay:0s;
    -moz-animation:bar1 1s linear forwards;
    -webkit-animation:bar1 1s linear forwards;
    background: #ff0000;

}
.running-bar #bar2 {
    -moz-animation-delay:0.9s;
    -webkit-animation-delay:0.9s;
    animation-delay:0s;
    -moz-animation:bar2 1s 1s linear forwards;
    -webkit-animation:bar2 1s 1s linear forwards;
    background: #32cd32;
}
.running-bar #bar3 {
    -moz-animation-delay:1.9s;
    -webkit-animation-delay:1.9s;
    animation-delay:0s;
    -moz-animation:bar3 1s 2s linear forwards;
    -webkit-animation:bar3 1s 2s linear forwards;
    background: #49E;
}

/* ANIMATION */

@-moz-keyframes bar1 {
    0%   { height:0px;}
    50%  { height:15px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@-moz-keyframes bar2 {
    0%   { height:0px;}
    50%  { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:60px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@-moz-keyframes bar3 {
    0%   { height:0px;}
    50%   { height:50px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:100px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@keyframes bar1 {
    0%   { height:0px;}
    50%   { height:15px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@keyframes bar2 {
    0%   { height:0px;}
    50%  { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:60px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@keyframes bar3 {
    0%   { height:0px;}
    50%  { height:50px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:100px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@-webkit-keyframes bar1 {
    0%   { height:0px;}
    50%  { height:15px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@-webkit-keyframes bar2 {
    0%   { height:0px;}
    50%  { height:30px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:60px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
}

@-webkit-keyframes bar3 {
    0%   { height:0px;}
    50%  { height:50px; box-shadow:0px 0px 1px 1px rgba(0, 0 , 0, 0.5);}
    100% { height:100px; box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.5);}
}

/* ABOUT US */

@media screen and (min-width: 1200px){
    .about-us-box{
        height:350px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px){
    .about-us-box{
        height:375px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .about-us-box{
        height:495px;
    }
    .about-us-box{
       height:600px;
    }

}

@media screen (max-height: 685px) and (min-width: 768px) and (max-width:991px){
    .about-us-box{
        height:5000px;
    }
    .about-us-box{
        height:435px;
    }
}

/*PRODUCTS*/

p.header-p{
    font-size: 16px;
}

#info-box h2{
    margin-bottom:15px;
}

#info-box-wrapper{
    margin:5px 0;
}


.info-box p, .info-box ul, .info-box a{
    text-align: left;
}


.team-text{
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.team-text p{
    text-align: justify;
}

.staff-img-wrapper{
    background-color: #005C99;

}

.staff-photo{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.team-text h2, .team-text h3{
    text-align: center;
    margin: 15px 0;
}

.team-text {
    padding: 0 20px;
    color:#000;
}

#staff-wrapper{
    margin-top: 50px;
}

@media screen and (min-width: 1200px) {

    .team-text {
       height: 400px;
    }
}

.team-page-link{
    text-align: center;
}

.ie8 #chart,
.ie9 #chart,
.ie10 #chart{
    background: url("/images/bar_chart.png") no-repeat;
    background-size: 300px 120px;
    width: 300px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
}

.ie8 .line-content,
.ie9 .line-content,
.ie10 .line-content,
#line-chart-canvas.no-animation{
    background: url("/images/line_graph.png") no-repeat;
    background-size: 300px 120px;
    width: 300px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
}

.ie8 #bars,
.ie9 #bars,
.ie10 #bars,
.ie8 .line,
.ie9 .line,
.ie10 .line,
.ie8 .line-left,
.ie8 .line-bottom,
.ie8 .ball,
.ie8 .pulse,
.ie9 .line-left,
.ie9 .line-bottom,
.ie9 .ball,
.ie9 .pulse,
.ie10 .line-left,
.ie10 .line-bottom,
.ie10 .ball,
.ie10 .pulse{
    display: none;
}

.product-image{
    width: 100%;
    height: 250px;
}

.box-header{
    font-size: 28px;
}

.attendance-btn{
    white-space: normal;
}

#eform-more{
    height:450px;
}

.attendance-box{
    margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
    .attendance-box{
        height:390px;
    }

    .attendance-btn{
        position: absolute;
        bottom: 40px;
        left: 30px;
    }

    .staff-img-wrapper{
        height:380px;
    }

}

/*POLICY PAGES*/

.pp-main-header{
    margin-bottom: 15px;
    text-align: center;
}

.pp-subheader{
    color:#005C99;
    font-size: 20px;
    font-weight: bold;
    margin-top:15px;
    margin-bottom:5px;

}

/* FORM */

.radio{
    display:inline-block;
}

.radio-wrapper{
    display: inline-block;
    vertical-align: top;
}

.radio-label{
    margin-right:10px;
    width:75px;
    text-align: right;
}

.split-line{
    display:inline-block;
    width: 45%;
    margin: 2%;
    padding: 5px;
}

.thankyou{
    display: none;
}

.thankyou h1{
    text-align: center;
    color:#005C99;
}

#staff_section{
    width:33%;
    display: inline-block;
    position: relative;
    padding:0;
}

.form-inner-header{
    margin: 0 5%;
    width:90%;
    text-align: center;
}

.form-inner-p{
    text-align: justify;
}

.form-group.has-error{
    border: 1px solid #a94442;
}

#form-inner-demo{
    margin-top: 20px;
}

#expenses-contact{
    height:756px;
}

.eform-subtitle{
    font-size: 14px;
    text-align: center;
}

#expenses-li-long{
    height:741px;
}

@media (max-width: 992px) {
    #staff_section{
        width: 100%;
    }

    #staff_section img{
        margin-left:25%;
        margin-top:2.5%;
    }
}

@media screen (max-width: 767px) {
    .team-text{
        width: 400px;
    }
}


/*Expenses Landing*/


#expenses-landing{
    background-color: #f5f5f5;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}


.text-wrapper-landing{
    background-color: white;
    border: 1px solid rgba(0, 0, 0, .5);
    margin: 30px 0;
    padding: 30px 0;
    width: 100%
}

#expenses-landing{
    background-color:#156CAE;
    background:url("/images/cover.jpg");
    background-size: cover;
        background-position: center;
    margin: 0;
}

#expenses-landing .landing-overlay {
    background-color: rgba(44, 62, 80, 0.3);
    background-image: url("../images/pattern.png");
    background-repeat: repeat;
    width: 100%;
    padding-bottom: 50px;
}


#expenses-landing-title{
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    width: 90%;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    margin-bottom: 50px;
}

.headline-info{
    margin-bottom: 20px;
}

.text-bkgrd{
    /*background-color: rgba(55,55,55,0.75);*/
    color: #FFF;
    font-weight: bold;
    font-size: 19px;
    padding: 10px 20px 0 10px;
    margin:0 0 5px;
    line-height: 25px;

}

.pre-form{
    color: #000;
}



h3.pre-form{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #156CAE;
}

p.pre-form{
    text-align: center;
    color: #666666;
}

.pre-form.text-bold{
    color: #E7746F;
}



#expenses-landing-form-container{
    background-color: #fff;
    opacity: 8;
    padding: 10px;
}

#expenses-landing-info-section{
    padding-top: 50px;
    padding-bottom: 0;
    color: #000
}

#ps-text{
    color: #156CAE;
    margin-top: 30px;
}

.border-wrapper{
    /*margin:10px;*/
    padding:20px 10px;
    border:1px solid #fe3732;
}

#expenses-landing-list li{
    color: #156CAE;
    font-weight: bold;
}

#expenses-landing-list li, #expenses-landing-info-section p{
    line-height: 20px;
}

#expenses-submit-btn{
    width:100%;
}

#expenses-landing-info-section p, #expenses-landing-list li{
    font-size: 18px;
}

#expenses-landing-list li{
    line-height: 22px;
}

@media screen and (max-width: 991px){
    #expenses-landing-form-container{
        margin-top: 30px;
        margin-bottom: 75px;
    }
}

@media screen and (min-width: 992px) and (max-width:1199px){
    #expenses-landing-form-container{
        position: relative;
        top: -200px;
    }
}

@media screen and (min-width: 1200px) and (max-width:1600px){
    #expenses-landing-form-container{
        position: relative;
        top: -120px;
    }
}

@media screen and (min-width: 1367px) and (max-width:1600px){
    #expenses-landing-form-container{
        margin-bottom: 75px;
    }
}

@media screen and (min-width: 1601px){
    #expenses-landing-form-container{
        position: relative;
        top: -120px;
    }
}


/*VIDEO*/
.product-video{
    margin-top: 10px;
}

#attendance .product-video{
    margin-top: -15px;
}


@media (min-width: 1200px){
    .col-lg-6.embed-responsive-16by9{
        padding-bottom: 28.125%;
    }
    .col-lg-5.embed-responsive-16by9{
        padding-bottom: 23.4375%;
    }
    .landing-video{
        top: -15px;
    }

}

@media (min-width: 992px){
    .col-md-6.embed-responsive-16by9{
        padding-bottom: 28.125%;
    }
    .col-md-5.embed-responsive-16by9{
        padding-bottom: 23.4375%;
    }

}

/*MOBILE CHANGES*/

/*products*/
/*ipad 2 portrait*/
@media (min-width: 768px) and ( max-width: 991px){
    .info-box-payslip, .attendance-box{
        height:100%;
        margin: 10px 0;
    }

    #expenses-contact{
        height:100%;
    }

    #expenses-li-long{
        height:100%;
    }



}

/*ipad 2 landscape*/
@media (max-height: 685px){
    .info-box-payslip, .attendance-box{
        height:100%;
        margin: 10px 0;
    }

    #expenses-contact{
        height:100%;
    }

    #expenses-li-long{
        height:100%;
    }



}

@media (max-width: 767px){
    .info-box-payslip, .attendance-box{
        margin: 10px 0;
    }

}

/* App page */

.app-link{
    text-align: center;
}

.app-link img{
    width: 165px;
}


/* ReCaptcha  */

.g-recaptcha{
    transform:scale(0.91);
    -webkit-transform:scale(0.91);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    width:304px;
    height:74px;
    display: inline-block;
}

.captcha-wrapper{
    text-align: center;
    display: inline-block;
    margin-left: 10px;
}
#captcha-wrapper-expenses{
    margin-left: 12.5%;
}

.recaptcha-validation{
    position: relative;
    right: 15px;
    display:none;
    color: #a94442;
}

